/*eslint-disable */
// import CareerCategoryModel from '../../Model/CareerCategory'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let CareerCategorys = {
  /**
   * careerCategoryList
   */
  async careerCategoryList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "careerCategory_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerCategoryList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * careerCategoryView
   */
  async careerCategoryView (context, careerCategoryId) {
    try {
      
      let post_data = new FormData();
      post_data.append('carr_cat_id', careerCategoryId);
      return await request.curl(context, "careerCategory_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at careerCategoryView() and Exception:",err.message)
    }
    
  },

  /**
   * careerCategoryAdd
   */
  async careerCategoryAdd (context, careerCategoryObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerCategoryObj) {
      post_data.append(key, careerCategoryObj[key]);
    }

    return await request.curl(context, "careerCategory_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerCategoryAdd() and Exception:',err.message)
    }
  },

  /**
   * careerCategoryEdit
   */
  async careerCategoryEdit (context, careerCategoryObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerCategoryObj) {
      post_data.append(key, careerCategoryObj[key]);
    }

    return await request.curl(context, "careerCategory_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerCategoryEdit() and Exception:',err.message)
    }
  },

  /**
   * careerCategoryDelete
   */
  async careerCategoryDelete (context, careerCategoryId) {
    try{
    let post_data = new FormData();
    
    post_data.append('carr_cat_id', careerCategoryId);

    return await request.curl(context, "careerCategory_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at careerCategoryDelete() and Exception:',err.message)
    }
  }
}

export {
  CareerCategorys
}
