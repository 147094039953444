<template>
  <b-container fluid >
    <b-row>
      <b-col  class="heading_alignment" md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title mb-3 mt-3">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="careerCategoryAdd" class="mb-3 mt-3">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template >
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="careerCategoryObjList && careerCategoryObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="careerCategoryObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <button class="actionIcon btn mr-1 mb-1" size="sm" @click="careerCategoryEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="font-size-20 ri-ball-pen-fill m-0"></i>
                    </button>
                    <button class="actionIcon btn" @click="showCareerCategoryDeleteDialog(data.item)" size="sm">
                      <i class="font-size-20 ri-delete-bin-line m-0"></i>
                    </button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelCareerCategoryAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
      >
      <CareerCategoryAdd :propOpenedInModal="true" @emitCloseCareerCategoryAddModal="closeCareerCategoryAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerCategoryAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerCategoryAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelCareerCategoryEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <CareerCategoryEdit :propOpenedInModal="true" :propCareerCategoryObj="careerCategoryEditObj" @emitCloseCareerCategoryEditModal="closeCareerCategoryEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerCategoryEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerCategoryEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelCareerCategoryDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCareerCategoryDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="careerCategoryDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { CareerCategorys } from "../../../FackApi/api/careerCategory"
import CareerCategoryAdd from "./CareerCategoryAdd"
import CareerCategoryEdit from "./CareerCategoryEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "CareerCategoryList",
  components: {
    CareerCategoryAdd,
    CareerCategoryEdit
  },
  data () {
    return {
      cvCardTitle: "Career Category",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Career Category",
      cvAddModalHeader: "Add Career Category",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Category List Response",
      showModelCareerCategoryAdd: false,
      showModelCareerCategoryEdit: false,
      showModelCareerCategoryDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "carr opp name", key: "carr_cat_name", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      careerCategoryObjList: null,
      careerCategoryEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  mounted () {
    this.careerCategoryList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * careerCategoryList
     */
    async careerCategoryList () {
      try {
        let careerCategoryListResp = await CareerCategorys.careerCategoryList(this)
        if (careerCategoryListResp.resp_status) {
          this.careerCategoryObjList = careerCategoryListResp.resp_data.data
          this.totalRows = careerCategoryListResp.resp_data.count
        }
        else {
          this.toastMsg = careerCategoryListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerCategoryList() and Exception:", err.message)
      }
    },
    /**
     * careerCategoryAdd
     */
    careerCategoryAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerCategory_add")
        }
        else {
          this.showModelCareerCategoryAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCategoryAdd() and Exception:", err.message)
      }
    },
    /**
     * careerCategoryEdit
     */
    careerCategoryEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerCategory_edit/" + this.careerCategoryEditObj.carr_cat_id)
        }
        else {
          this.careerCategoryEditObj = item
          this.showModelCareerCategoryEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCategoryEdit() and Exception:", err.message)
      }
    },
    /**
     * showCareerCategoryDeleteDialog
     */
    showCareerCategoryDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCareerCategoryDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCareerCategoryDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * careerCategoryDelete
     */
    async careerCategoryDelete () {
      try {
        let careerCategoryDelResp = await CareerCategorys.careerCategoryDelete(this, this.delObj.carr_cat_id)
        await ApiResponse.responseMessageDisplay(this, careerCategoryDelResp)
        if (careerCategoryDelResp && !careerCategoryDelResp) {
          this.showModelCareerCategoryDelete = false
          return false
        }
        let index = this.careerCategoryObjList.indexOf(this.delObj)
        this.careerCategoryObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCareerCategoryDelete = false
      }
      catch (err) {
        console.error("Exception occurred at careerCategoryDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCareerCategoryAddModal
     */
    closeCareerCategoryAddModal (careerCategoryAddData) {
      try {
        if (careerCategoryAddData) {
          if (this.careerCategoryObjList && this.careerCategoryObjList.length >= 1) {
            let carrcatObjLength = this.careerCategoryObjList.length
            let lastId = this.careerCategoryObjList[carrcatObjLength - 1]["id"]
            careerCategoryAddData.id = lastId + 1
          }
          else {
            this.careerCategoryObjList = []
            careerCategoryAddData.id = 11111
          }
          careerCategoryAddData.carr_cat_id = `CARRCAT${careerCategoryAddData.id}`
          careerCategoryAddData.created_on = moment()
          this.careerCategoryObjList.unshift(careerCategoryAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelCareerCategoryAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecareerCategoryAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCareerCategoryEditModal
     */
    closeCareerCategoryEditModal () {
      try {
        this.showModelCareerCategoryEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCareerCategoryEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
