<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <ValidationObserver ref="carrForm">
              <form action="#" v-if="vmCareerCategoryFormData && Object.keys(vmCareerCategoryFormData).length > 0">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_cat_name">
                      {{cvCarrOppNameLabel}}</label>
                      <ValidationProvider
                        name="Career Category Name"
                        rules="required"
                        v-slot="{ errors }">
                        <input
                          v-model="vmCareerCategoryFormData.carr_cat_name"
                          type="text"
                          class="form-control"
                          required/>
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row spinner_add">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="careerCategoryEdit()">
                    {{cvSubmitBtn}}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { CareerCategorys } from "../../../FackApi/api/careerCategory"
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerCategoryEdit",
  components: {
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCareerCategoryObj: {
      type: Object,
      default: function () {
        return {
          "carr_cat_name": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit career Category",
      cvCardSubHeader: "Edit Career Category",
      cvSubmitBtn: "Edit",
      cvCarrOppNameLabel: "Career Opportunity Name",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "CareerCategory",
      vmCareerCategoryFormData: {}
    }
  },
  mounted () {
    this.careerCategoryView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerCategoryFormData) {
          if (!this.vmCareerCategoryFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * careerCategoryView
     */
    async careerCategoryView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCareerCategoryFormData = this.propCareerCategoryObj
        }
        else {
          let careerCategoryId = this.$route.params.carr_cat_id
          let careerCategoryViewResp = await CareerCategorys.careerCategoryView(this, careerCategoryId)
          if (careerCategoryViewResp && careerCategoryViewResp.resp_status) {
            this.vmCareerCategoryFormData = careerCategoryViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCategoryView() and Exception:", err.message)
      }
    },
    /**
     * careerCategoryEdit
     */
    async careerCategoryEdit () {
      let valid = await this.$refs.carrForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        let careerCategoryAddResp = await CareerCategorys.careerCategoryEdit(this, this.vmCareerCategoryFormData)
        await ApiResponse.responseMessageDisplay(this, careerCategoryAddResp)
        if (careerCategoryAddResp && !careerCategoryAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerCategoryEditModal", this.vmCareerCategoryFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCategoryEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
